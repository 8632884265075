import { createSelector } from 'reselect'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import { seriesRootSelector } from './seriesHooks'
import useShallowSelector from '../../../components/hooks/useShallowSelector'
import { useSelector } from 'react-redux'
import toSrcSet from '../helpers/toSrcSet'
import formatSeconds from '../../../common/formatSeconds'
import { durationToSeconds } from '../../../common/parseIsoDuration'
import toTime from '../../../RadioUI/Share/helpers/toTime'
import toUrlTimestamp from '../../../RadioUI/Share/helpers/toUrlTimestamp'
import { progressesSelector } from '../../../redux/personalisation/progressHooks'
import PROGRESS_STATES from '../../../redux/personalisation/helpers/progressStates'
import localeFormat from '../../../common/localeFormat'

const emptyObject = {}
const emptyArray = []

export const episodeSelector = createSelector([seriesRootSelector, progressesSelector], (seriesRoot, progresses) => {
  const episode = seriesRoot.episode
  const progress = progresses.find(progress => progress.episodeId === episode.episodeId)
  if (progress) {
    const progressState = progress.progress
    const percentage = _get(progress, `${PROGRESS_STATES.IN_PROGRESS}.percentage`)
    const startPoint = _get(progress, `${PROGRESS_STATES.IN_PROGRESS}.timeInSeconds`)
    const progressAccessibilityValue = progress.accessibilityValue

    return { ...episode, progressState, percentage, startPoint, progressAccessibilityValue }
  }
  return episode
})

export const useEpisode = () => {
  const episode = useShallowSelector(episodeSelector)
  return episode
}

const episodeIdSelector = createSelector([episodeSelector], episode => episode.episodeId)

export const useEpisodeId = () => {
  const episodeId = useSelector(episodeIdSelector)
  return episodeId
}

const clipIdSelector = createSelector([episodeSelector], episode => episode.clipId)

export const useClipId = () => {
  const clipId = useSelector(clipIdSelector)
  return clipId
}

const episodeProgressStateSelector = createSelector([episodeSelector], episode => episode.progressState)

export const useEpisodeProgressState = () => {
  const progressState = useSelector(episodeProgressStateSelector)
  return progressState
}

const episodePercentageSelector = createSelector([episodeSelector], episode => {
  if (episode.progressState === PROGRESS_STATES.FINISHED) {
    return 100
  }
  return episode.percentage
})

export const useEpisodePercentage = () => {
  const percentage = useSelector(episodePercentageSelector)
  return percentage
}

const episodeStartPointSelector = createSelector([episodeSelector], episode => episode.startPoint)

export const useEpisodeStartPoint = () => {
  const startPoint = useSelector(episodeStartPointSelector)
  return startPoint
}

const episodeProgressAccessibilityValueSelector = createSelector(
  [episodeSelector],
  episode => episode.progressAccessibilityValue
)

export const useEpisodeProgressAccessibilityValue = () => {
  const progressAccessibilityValue = useSelector(episodeProgressAccessibilityValueSelector)
  return progressAccessibilityValue
}

const episodeIsLoadingSelector = createSelector([episodeSelector], ({ loading }) => loading)

export const useEpisodeIsLoading = () => {
  const isLoading = useSelector(episodeIsLoadingSelector)
  return isLoading
}

const episodeTitleSelector = createSelector([episodeSelector], episode => _get(episode, 'titles.title'))
export const useEpisodeTitle = () => {
  const episodeTitle = useSelector(episodeTitleSelector)
  return episodeTitle
}

const episodeDescriptionSelector = createSelector([episodeSelector], episode => _get(episode, 'titles.subtitle'))

export const useEpisodeDescription = () => {
  const episodeDescription = useSelector(episodeDescriptionSelector)
  return episodeDescription
}

const episodeDurationSelector = createSelector([episodeSelector], episode => _get(episode, 'duration.iso8601'))

export const useEpisodeDuration = () => {
  const duration = useSelector(episodeDurationSelector)
  return duration
}

const episodeDetailsSelector = createSelector([episodeSelector], episode =>
  _get(episode, 'programInformation.details.displayValue')
)

export const useEpisodeDetails = () => {
  const episodeDetails = useSelector(episodeDetailsSelector)
  return episodeDetails
}

const episodeDateFormattedSelector = createSelector([episodeSelector], ({ date: dateIsoStr }) => {
  if (!dateIsoStr) {
    return null
  }
  const date = new Date(dateIsoStr)
  return localeFormat(date, 'DD.MM.YYYY')
})

export const useEpisodeDateFormatted = () => {
  const dateStr = useSelector(episodeDateFormattedSelector)
  return dateStr
}

const episodeImageRawSelector = createSelector([episodeSelector], episode => _get(episode, 'image'))

export const useEpisodeImageRaw = () => {
  const imageArr = useShallowSelector(episodeImageRawSelector)
  return imageArr
}

const episodeImageSelector = createSelector([episodeImageRawSelector], image => {
  if (Array.isArray(image) && image.length > 0) {
    const src = image.find(({ width }) => width >= 260).url
    const srcSet = toSrcSet(image)
    return { src, srcSet }
  }
  return emptyObject
})

export const useEpisodeImage = () => {
  const episodeImage = useShallowSelector(episodeImageSelector)
  return episodeImage
}

const episodeAssociatedSeasonIdSelector = createSelector([episodeSelector], episode => {
  return _get(episode, '_links.season.name')
})

export const useEpisodeAssociatedSeasonId = () => {
  const associatedSeasonId = useSelector(episodeAssociatedSeasonIdSelector)
  return associatedSeasonId
}

const episodeAssociatedSeriesIdSelector = createSelector([episodeSelector], episode => {
  return _get(episode, '_links.series.name')
})

export const useEpisodeAssociatedSeriesId = () => {
  const associatedSeriesId = useSelector(episodeAssociatedSeriesIdSelector)
  return associatedSeriesId
}

const indexPointsSelector = createSelector([episodeSelector], ({ indexPoints }) => {
  if (!indexPoints) return emptyArray
  return indexPoints.map(indexPoint => {
    const startTimeInSeconds = durationToSeconds(indexPoint.startPoint)
    const displayStartTime = formatSeconds(startTimeInSeconds)
    const urlTimestamp = toUrlTimestamp(toTime(startTimeInSeconds))
    return {
      ...indexPoint,
      startTimeInSeconds,
      displayStartTime,
      urlTimestamp
    }
  })
})

const playlistSelector = createSelector([episodeSelector], episode => {
  const playlist = episode.playlist

  if (!playlist) return emptyArray

  return playlist.map(playlistItem => {
    const startTimeInSeconds = durationToSeconds(playlistItem.startPoint)
    const displayStartTime = formatSeconds(startTimeInSeconds)
    const urlTimestamp = toUrlTimestamp(toTime(startTimeInSeconds))

    return {
      ...playlistItem,
      startTimeInSeconds,
      displayStartTime,
      urlTimestamp
    }
  })
})

const indexPointsAndPlaylistSelector = createSelector(
  [indexPointsSelector, playlistSelector],
  (indexPoints, playlist) => {
    return _sortBy([...indexPoints, ...playlist], 'startTimeInSeconds')
  }
)

export const useIndexPointsAndPlaylist = () => {
  const indexPointsAndPlaylist = useShallowSelector(indexPointsAndPlaylistSelector)
  return indexPointsAndPlaylist
}

const availableFromSelector = createSelector([episodeSelector], episode =>
  _get(episode, 'usageRights.from.displayValue')
)

export const useAvailableFrom = () => {
  const availableFrom = useSelector(availableFromSelector)
  return availableFrom
}

const availableToSelector = createSelector([episodeSelector], episode => {
  const dateStr = _get(episode, 'usageRights.to.date')
  if (dateStr && new Date(dateStr).getUTCFullYear() === 9999) {
    return 'Alltid tilgjengelig'
  }
  return _get(episode, 'usageRights.to.displayValue')
})

export const useAvailableTo = () => {
  const availableTo = useSelector(availableToSelector)
  return availableTo
}

const productionYearSelector = createSelector([episodeSelector], episode => episode.productionYear)

export const useProductionYear = () => {
  const productionYear = useSelector(productionYearSelector)
  return productionYear
}

const durationDisplayValueSelector = createSelector([episodeSelector], episode =>
  _get(episode, 'duration.displayValue')
)

export const useDurationDisplayValue = () => {
  const durationDisplayValue = useSelector(durationDisplayValueSelector)
  return durationDisplayValue
}

const durationInSecondsSelector = createSelector([episodeSelector], episode => _get(episode, 'duration.seconds'))

export const useDurationInSeconds = () => {
  const durationInSeconds = useSelector(durationInSecondsSelector)
  return durationInSeconds
}

const contributorsSelector = createSelector([episodeSelector], episode => {
  return _get(episode, 'contributors', []).reduce((acc, { role, name: names = [] }) => {
    const rolesNames = names.map(name => ({ role, name }))
    return [...acc, ...rolesNames]
  }, [])
})

export const useContributors = () => {
  const contributors = useShallowSelector(contributorsSelector)
  return contributors
}

const availabilitySelector = createSelector([episodeSelector], episode => _get(episode, 'availability', emptyObject))

export const useAvailability = () => {
  const availability = useShallowSelector(availabilitySelector)
  return availability
}
